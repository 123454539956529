.btn-link {
    text-decoration: none;
    color: black;
}

.btn-link:hover {
    text-decoration: none;
    color: black;
}

.btn-link:active {
    color: black !important;
}

.btn-close:focus {
    box-shadow:
        0px 0px 0px rgba(0, 0, 0, 0.075) inset,
        0px 0px 0px rgba(38, 40, 37, 0.8);
}

.btn {
    font-size: 14px;
    font-weight: 500;
}

.btn-dark:hover {
    box-shadow: 0 1px 1px rgba(11, 18, 26, 0.5);
    background-color: black;

}