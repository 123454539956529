textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="checkbox"]:focus,
input[type="file"]:focus,
textarea:focus,
.form-select:focus,
.uneditable-input:focus {
    border-color: rgba(38, 40, 37, 0.8) !important;
    box-shadow:
        0 1px 1px rgba(0, 0, 0, 0.075) inset,
        0 0 1px rgba(38, 40, 37, 0.8) !important;
    outline: 0 none !important;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.1rem rgba(220, 53, 69, 0.25);
}

input[type="checkbox" i]:focus-visible {
    outline-offset: 2px;
    outline-color: black;
}
