.ngb-tp-meridian {
    .btn-outline-primary {
        color: black;
        border-color: black;
    }

    .btn:hover {
        color: white;
        background-color: black;
    }
}
