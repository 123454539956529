.org-sidenav {
    background-color: #f6f6f6;
    overflow-x: hidden;
    overflow-y: auto;
    width: 340px;
    height: 100%;
    transition: all 0.3s;
}

.org-panel {
    width: 340px !important;
}