/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@use "bootstrap/scss/bootstrap";

@use "styles/variables";
@use "styles/controls/navbar";
@use "styles/controls/button";
@use "styles/controls/card";
@use "styles/controls/typography";
@use "styles/controls/sidenav";
@use "styles/controls/input";
@use "styles/controls/progressbar";
@use "styles/controls/accordian";
@use "styles/controls/time-picker";
@use "styles/controls/tab";
@use "styles/controls/modal";

html,
body {
    background-color: variables.$background-color;
    transition: all 0.1s linear 0s;
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: "Roboto", sans-serif;

    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
}

a {
    text-decoration: none;
    outline: none;
    color: black;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    >.col,
    >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.customPopover {
    max-width: none;
    width: 580px !important;
}

.form-check-input:checked {
    background-color: black !important;
    border-color: black;
}

.form-check-input:checked {
    box-shadow: none;
}

.form-check-input:active {
    filter: none;
}

.text-sm {
    font-size: 14px;
}

.no-pointer-events {
    pointer-events: none;
}

.ngb-dp-weekday {
    color: black !important;
    font-weight: bold;
}

#content[aria-hidden=true] {
    display: none;
}

.invalid-feedback-show {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: red;
}

.dot {
    height: 10px;
    width: 10px;
    // background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}