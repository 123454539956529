.accordion-button {
    background-color: #f6f6f6 !important;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:focus-visible {
    outline: none;
}
.accordion-button:not(.collapsed) {
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
