.progress-bar.indeterminate {
    position: relative;
    animation: progress-indeterminate 3s linear infinite;
    background-color: black;
}

.progress {
    height: 2px !important;
}

@keyframes progress-indeterminate {
    from {
        left: -25%;
        width: 25%;
    }
    to {
        left: 100%;
        width: 25%;
    }
}
